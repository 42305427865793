import "./index.css"

function Transport() {
    return(
        <div className="Transport">
            <div className="Transport__label">
                <h3>Địa chỉ 1</h3>
                <span>Mặc định</span>
            </div>
            <p>Số nhà abc, Phường 1, thành phố Trà Vinh, tỉnh Trà Vinh</p>
        </div>  
    )
}

export default Transport;