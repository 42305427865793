import "./index.css"

function PhoneNumber () {
    return (
        <div className="PhoneNumber">
            <h3>Số điện thoại</h3>
            <input type="text" placeholder="Nhập số điện thoại"/>
        </div>
    )
}

export default PhoneNumber;